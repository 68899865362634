import PropTypes from 'prop-types';
import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import {Link} from 'gatsby';

import {useLocation} from '@reach/router';
import {usePageContent} from 'src/functionality/content-provider';

import Page from 'src/components/functional/page';
import {Container, Col, Row} from 'react-bootstrap';
import {theme} from 'styles/theme';
import PlainHeroSection from 'src/components/functional/hero-section-plain';
import placeholderImage from 'images/placeholder.jpg';
import ContactButton from 'src/components/functional/contact-button';
import {H3, H4, H6} from 'src/components/functional/headings';
import ContentBlock from 'src/components/functional/content-block';
import ArbiasButton from 'src/components/functional/arbias-button';
import MoreAboutUs from 'src/components/functional/more-about-us-section';
import {breakpoints} from 'src/styles/breakpoints';
import {useFeatureFlags} from 'src/functionality/flags-provider';
import Spacer from 'src/components/graphical/spacer';
import {getResource} from 'src/functionality/resources-provider';
import Tile from 'src/components/functional/tile';

const CategoryButton = styled.button`
  margin: 0.25em 0;
  color: ${(props) => props.selected ? theme.primary : theme.muted};
  transition: all 0.15s ease-in-out;
  text-align: left;
`;

const LinkIcon = styled.i`
  margin: 0 0.25em;
  padding: 0.25em;
  width: 1.5em;
  text-align: center;
  transition: all 0.05s ease-in-out;
  border-radius: 2px;
  & :hover{
    filter: brightness(0.9);
  }
`;

const CategoryLi = styled.li`
  font-size: 1.25em;
`;
const CategoryUl = styled.ul`
  margin: 0;
  padding: 0;
`;

const Wrapper = styled(Container)`
  display: flex;
  margin-top: 1em;
`;

const AsideDiv = styled(Col)`
  border-bottom: 1px solid ${theme.mediumGray};
  padding: 0.5em 0;
  
  @media ${breakpoints.md} {
    text-align: center;
    border: 0;
  }
`;

const AsideInformationExplorer = styled(Col)`
  margin: 0.5em 0;
  padding: 1em 1vw;
  background-color: ${theme.bodyBg};
  
  @media ${breakpoints.md} {
    border: 0;
    text-align: center;
  }
`;

const Main = styled.main`
  & blockquote {
    padding: 0.5rem 2vw;
    background-color: ${theme.primary};
    border: 0;
    color: ${theme.light};
    border-top: 0.3em solid ${theme.darkPrimary};
  }
  & blockquote a {
    color: black;
  }
  
  & blockquote > p {
    margin-bottom: 0.2rem;
  }
  
  ul, ol{
    list-style-type: unset;
  }
  ul p, ol p{
    margin-bottom: 0.25em;
  }
`;

const ButtonLink = styled(Link)`
  display: block;
  width: 100%;
  margin: calc(var(--bs-gutter-x)) 0;
  color: ${theme.dark};
  font-size: large;
  text-decoration: none;
  transition: all 0.15s ease-in-out;
  
  & h6{
    transition: all 0.15s ease-in-out;
  }

  &:hover h6{
    color: ${theme.primary}!important;
  }

  & p{
    padding-right: 2rem;
  }
`;

const RelatedSection = styled(Row)`
  padding: 0.5rem 2vw;
  background-color: ${theme.bodyBg};
`;

const Sidebar = styled(Col)`
  @media ${breakpoints.md} {
    border-bottom: 1px solid ${theme.mediumGray};
    margin-bottom: 1em;
    background-color: ${theme.bodyBg};
  }
`;

const Center = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Accordion = styled.div`
  display: ${(props) => props.hidden ? 'none': 'unset'};
  &:before{
    content:${(props) => props.level};
  }
`;

const Heading = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.level === 1 ? theme.bodyBg : 'unset'};
  margin: ${(props) => props.level === 1 ? '0.5em 0' : 'unset'};
  cursor: ${(props) => props.level === 0 ? 'unset' : 'pointer'};
  & i{
    padding-bottom: 0.75em;
  }
  h1, h2{
    color: ${() => theme.primary};
  }
  h2{
    font-size: 1.4em;
  }
  h3{
    font-size: 1.2em;
  }
  h4{
    font-size: 1.1em;
    font-style: italic;
  }
  h4{
    font-size: 1em;
    color: ${()=>theme.primary};
    font-style: italic;
  }
`;

const DownloadsWrapper = styled.div`
  display: flex;
  align-items: top;
  background-color: ${theme.bodyBg};
  padding: 1em;
  margin: 0.5em 0;
  & > div{
    flex-grow: 1;
  }
`;

const AccordionArrow = styled.i`
  display: unset!important;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  margin-top: 0.75em;
  font-size: 1.5rem!important;
  z-index: 1;
  transition: all 0.25s ease;
  
  &:after{
    content: '';
    display: block;
  }
`;

const InformationContentSchema = [
  {'tag': 'I07', 'inner': [
    {'tag': 'I07-2', 'inner': []},
    {'tag': 'I07-3', 'inner': []},
    {'tag': 'I07-1', 'inner': [
      {'tag': 'I07-1-1', 'inner': []},
      {'tag': 'I07-1-2', 'inner': []},
      {'tag': 'I07-1-3', 'inner': []},
    ]},
  ]},
  {'tag': 'I04', 'inner': [
    {'tag': 'I04-1', 'inner': []},
    {'tag': 'I04-2', 'inner': [
      {'tag': 'I04-2-1', 'inner': []},
      {'tag': 'I04-2-2', 'inner': []},
      {'tag': 'I04-2-3', 'inner': []},
      {'tag': 'I04-2-4', 'inner': []},
      {'tag': 'I04-2-5', 'inner': []},
      {'tag': 'I04-2-6', 'inner': []},
      {'tag': 'I04-2-7', 'inner': [
        {'tag': 'I04-2-7-1', 'inner': []},
        {'tag': 'I04-2-7-2', 'inner': []},
        {'tag': 'I04-2-7-3', 'inner': []},
        {'tag': 'I04-2-7-4', 'inner': []},
      ]},
    ]},
  ]},
  {'tag': 'I06', 'inner': [
    {'tag': 'I06-1', 'inner': [
      {'tag': 'I06-1-1', 'inner': []},
      {'tag': 'I06-1-2', 'inner': []},
      {'tag': 'I06-1-3', 'inner': []},
    ]},
    {'tag': 'I06-2', 'inner': []},
    {'tag': 'I06-3', 'inner': []},
    {'tag': 'I06-4', 'inner': []},
  ]},
];

const fileDownloads = {
  'I06-1-3': [
    {
      title: 'NPAIS Government Funded Referral Form',
      url: 'resources/Referral-forms/NPAIS-Government-Funded-Referral-Form.pdf',
    },
    {
      title: 'NPAIS Government Funded Client Consent Form',
      // eslint-disable-next-line max-len
      url: 'resources/Referral-forms/NPAIS-Government-Funded-Client-Consent-Form.pdf',
    },
  ],
  'I06-1-1': [
    {
      title: 'NPAIS NDIS Funded Referral Form',
      url: 'resources/Referral-forms/NPAIS-NDIS-Referral-Form.pdf',
    },
    {
      title: 'NPAIS NDIS Funded Client Consent Form',
      url: 'resources/Referral-forms/NPAIS-NDIS-Client-Consent-Form.pdf',
    },
    {
      title: 'NPAIS NDIS Service Agreement',
      url: 'resources/Referral-forms/NPAIS-NDIS-Service-Agreement.pdf',
    },
  ],
  'I06-1-2': [
    {
      title: 'NPAIS Private Clinic Referral Form',
      url: 'resources/Referral-forms/NPAIS-Private-Clinic-Referral-Form.pdf',
    },
    {
      title: 'NPAIS Private Clinic Client Consent Form',
      // eslint-disable-next-line max-len
      url: 'resources/Referral-forms/NPAIS-Private-Clinic-Client-Consent-Form.pdf',
    },
  ],
};

const InformationPage = ({pageContext}) => {
  const location = useLocation();
  const content = usePageContent(location.pathname, pageContext.content);
  const [categories, setCategories] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [hiddenTags, setHiddenTags] = useState({});

  useEffect(() => {
    const populateHiddenTags = (category, level) =>{
      level = level ?? 0; // if first iteration, set level to 0
      category.forEach((innerCategory) => {
        // if tag hasn't been populated in HiddenTags list yet do so
        if (hiddenTags[innerCategory.tag] === undefined) {
          setHiddenTags(
              {...hiddenTags, [innerCategory.tag]: (level === 2)},
          );
        }
        populateHiddenTags(innerCategory.inner, level + 1);
      });
    };
    populateHiddenTags(InformationContentSchema);
  }, [hiddenTags]);

  useEffect(() => {
    if (!categories) {
      setCategories(InformationContentSchema.map((category) => category.tag));
      setCategories(InformationContentSchema.map((category) => category.tag));
    }
  }, [categories]);

  const changeCategory = useCallback((category) => {
    if (categories.includes(category)) {
      setSelectedCategory(category);
    } else {
      setSelectedCategory(InformationContentSchema[0].tag);
    }
    history.pushState(null, null, `#${category}`);
  }, [categories]);

  useEffect(() => {
    if (categories) {
      changeCategory(location.hash.substring(1));
    }
  }, [categories, changeCategory, location.hash]);

  /**
   * Converts Information content schema into JSX
   * @param {list} category A recursive list of objects in format
   * @param {int} level Describing the level of nesting
   * {'contentTag': [category]}
   * @return {jsx} JSX representing the category (recursive)
   */
  const renderCategory = useCallback((category, level) => {
    level = level ?? 0; // if first iteration, set level to 0
    const Hx = `h${level > 4 ? 4 : level+1}`;
    return category.map((innerCategory, key) => {
      return (
        <ContentBlock
          key={key}
          header={(props) =>
            <>
              <Heading
                level={level}
                onClick={() => {
                  if (level !== 0) {
                    setHiddenTags({
                      ...hiddenTags,
                      [innerCategory.tag]: !hiddenTags[innerCategory.tag],
                    });
                  }
                }}
              >
                {level !== 0 &&
                <AccordionArrow
                  hidden={hiddenTags[innerCategory.tag]}
                  className={hiddenTags[innerCategory.tag] ?
                    'fa fa-angle-up' : 'fa fa-angle-down'}
                />
                }
                <Hx // Not actually, see 'as={...}' below
                  {...props}
                />
              </Heading>
              {level === 2 && !hiddenTags[innerCategory.tag] &&
                <hr />
              }
            </>
          }
          content={content}
          tag={innerCategory.tag}
          contentWrapper={(props) =>(
            <Accordion
              hidden={hiddenTags[innerCategory.tag]}
            >
              <div {...props}/>
              {renderCategory(innerCategory.inner, level + 1)}
              {
                fileDownloads[innerCategory.tag] &&
                <DownloadsWrapper>
                  {
                    fileDownloads[innerCategory.tag].map((download, key) =>
                      <Tile
                        key={key}
                        isRounded={true}
                        centered={true}
                        type='document'
                        onClick={() =>
                          getResource(download.url)
                              .then((url) => window.open(url))}
                        title={download.title}
                      />,
                    )}
                </DownloadsWrapper>
              }
            </Accordion>
          )}
        />
      );
    });
  }, [content, hiddenTags]);

  const flags = useFeatureFlags();

  return (
    <Page white="true">
      <PlainHeroSection
        backgroundColor={theme.mediumGray}
        breadcrumbs="true"
        content={content}
        tag="I01"
        bgImage={placeholderImage}
      />
      <Wrapper>
        <Row style={{width: '100%'}}>
          <Sidebar lg={3}>
            <aside>
              <Row>
                <AsideDiv lg={12}>
                  <h4 className="text-center text-primary">
                    Categories
                  </h4>
                  <CategoryUl>
                    {categories?.map((category, key) => (
                      <CategoryLi key={key}>
                        <H6 noUnderline>
                          <CategoryButton
                            className="plain-button"
                            onClick={() => changeCategory(category)}
                            selected={selectedCategory === category}
                          >
                            {content && content[category]?.title}
                          </CategoryButton>
                        </H6>
                      </CategoryLi>
                    ))}
                  </CategoryUl>
                </AsideDiv>
                {flags.EnableInformationExplorer &&
                  <AsideInformationExplorer lg={12} md={6}>
                    <ContentBlock
                      header={(props) =>
                        <H4 noUnderline="true" {...props} display={6}/>}
                      content={content}
                      tag="I08"
                      link={(props) =>
                        <ArbiasButton {...props} to="/information/explorer/"/>}
                      linkText="Try It >"
                    />
                  </AsideInformationExplorer>
                }
                <div style={{borderBottom: `1px solid ${theme.mediumGray}`}}/>
                <AsideDiv lg={12} md={12}>
                  <Center>
                    <ContactButton />
                  </Center>
                </AsideDiv>
                <AsideDiv lg={12} md={12}>
                  <Center>
                    <ArbiasButton to="/information/complaints-and-compliments">
                      Compliments &amp; Complaints
                    </ArbiasButton>
                  </Center>
                </AsideDiv>
              </Row>
            </aside>
          </Sidebar>
          <Col lg={9}>
            <Main>
              {!flags.Information_UseOldInfoSchema &&
              content &&
              selectedCategory &&
              <>
                {renderCategory([InformationContentSchema[
                    InformationContentSchema.findIndex((obj) =>
                      obj.tag === selectedCategory)]],
                )}
              </>
              }
              {flags.Information_UseOldInfoSchema &&
              selectedCategory.contents.map((contentTag, key) => (
                <ContentBlock
                  key={key}
                  header={(props) =>
                    <span id={`${content?.[contentTag]?.title}`}>
                      <a href={`#${selectedCategory.name}`}>
                        <LinkIcon className="fa fa-link" />
                      </a>
                      <H3 {...props}/>
                    </span>
                  }
                  content={content}
                  tag={contentTag}
                />
              ))
              }
              {flags.Information_RelatedCategories &&
              <RelatedSection>
                <H4 noUnderline="true" className="text-center">Related</H4>
                {selectedCategory.related.map((relatedLink, key) => (
                  <Col key={key} md={12} lg={4}>
                    <ButtonLink to={relatedLink.link}>
                      <ContentBlock
                        key={key}
                        header={(props) =>
                          <H6 noUnderline className="text-muted" {...props}/>
                        }
                        content={content}
                        tag={relatedLink.contentTag}
                      />
                    </ButtonLink>
                  </Col>
                ))}
              </RelatedSection>
              }
              <Spacer height={2}/>
              <hr />
              <Spacer height={2}/>
              <section>
                <ContentBlock
                  header={(props) => <H3 {...props}/>}
                  content={content}
                  tag='I10'
                  linkText='Documents &amp; Resources'
                  link={
                    (props) =><ArbiasButton
                      {...props}
                      to="/about/resources/"
                    />
                  }
                />
              </section>
              <Spacer height={4}/>
            </Main>
          </Col>
        </Row>
      </Wrapper>
      <MoreAboutUs />
    </Page>
  );
};

InformationPage.propTypes = {
  pageContext: PropTypes.shape({
    content: PropTypes.object,
  }),
};

export default InformationPage;


